import * as actionTypes from "../actions/actionTypes";

const initialState = {
  exams: [],
  examImg: {},
  studentCertificate: {},
  studentMarks: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STUDENT_CERTIFICATE:
      return {
        ...state,
        studentCertificate: action.studentCertificate,
      };
    default:
      return state;
  }
};

export default reducer;
