import React, { useRef, useState } from "react";
import FsLightbox from "fslightbox-react";
import { FaPlay } from "react-icons/fa";
import CourseTypeFilter from "../../components/course/CourseTypeFilter";

const HomeTwoCourses = ({
  onlineCourses,
  title,
  nameKey,
  idKey,
  productType,
}) => {
  return (
    <>
      <div className="edu-course-area eduvibe-home-two-course edu-section-gap bg-color-white">
        <div className="container">
          <CourseTypeFilter
            CourseData={onlineCourses}
            title={title}
            nameKey={nameKey}
            idKey={idKey}
            productType={productType}
          />
        </div>
      </div>
    </>
  );
};

export default HomeTwoCourses;
