import * as actionTypes from "./actionTypes";
import axios from "../axios";

export const GetDistrict = () => {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    let config = {
      headers: {
        // Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios.get(`/Website/Districts`, config).then((response) => {
      console.log(response.data);
      dispatch({
        type: actionTypes.GET_DISTRICTS,
        districts: response.data,
      });
    });
  };
};

export const GetDistrictByState = (stateId) => {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    let config = {
      headers: {
        // Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios
      .get(`/Website/GetDistrictByStateId/${stateId}`, config)
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: actionTypes.GET_DISTRICTS,
          districts: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: actionTypes.GET_DISTRICTS_FAIL,
        });
      });
  };
};

export const GetStates = () => {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    let config = {
      headers: {
        // Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios.get(`/Website/GetStates`, config).then((response) => {
      console.log(response.data);
      dispatch({
        type: actionTypes.GET_STATES,
        states: response.data,
      });
    });
  };
};
