import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
// import RootReducer from './reducers/RootReducer';
import { combineReducers } from "redux";

import studentReducer from "./reducers/studentReducer";
import districtAndStateReducer from "./reducers/districtAndStateReducer";
import examReducer from "./reducers/examReducer";
import coursesReducer from "./reducers/coursesReducer";

const initialState = {};
const middlewares = [thunk];
let devtools = (x) => x;

// if (
//   process &&
//   process.env.NODE_ENV !== "production" &&
//   process.browser &&
//   window.__REDUX_DEVTOOLS_EXTENSION__
// ) {
//   devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
// }

const RootReducer = combineReducers({
  student: studentReducer,
  districtAndState: districtAndStateReducer,
  exam: examReducer,
  courses: coursesReducer,
});

export const Store = createStore(
  RootReducer,
  initialState,
  compose(applyMiddleware(...middlewares), devtools)
);
