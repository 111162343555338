import React from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../common/breadcrumb/BreadcrumbOne";

const TermsCondition = () => {
  return (
    <>
      <SEO title="Terms and Conditions" />
      <Layout>
        <BreadcrumbOne
          title="Terms and Conditions"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="terms-condition"
        />
        <section
          className="pt-8 pt-md-11 pb-8 pb-md-14"
          style={{ marginTop: 50, marginBottom: 50 }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <p className="font-size-lg text-gray-800 mb-6 mb-md-8">
                  These terms and conditions apply to the User who uses the
                  Online Services provided for any payment made to Prompt
                  Enterprises. Kindly read these terms and conditions carefully.
                  By authorizing a payment to Prompt Enterprises through the
                  online payment service (&quot;the service&quot;), it would be
                  treated as a deemed acceptance to these terms and conditions.
                  Prompt Enterprises reserves all the rights to amend these
                  terms and conditions at any time without giving prior notice.
                  It is the responsibility of the User to have read the terms
                  and conditions before using the Service.{" "}
                </p>

                <h3>KEY TERMS</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Payment(s) through this Service may only be made with a
                    Credit Card, Debit card, UPI, Wallets or Net Banking.{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Before using this Service, it is recommended that the user
                    shall make necessary enquiry about the charges or fees
                    payable against the payment method used from Credit Card,
                    Debit card, UPI, Wallets or Net Banking service provider
                    i.e. the respective Bank.{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    The credit card information supplied at the time of using
                    the service is processed by the payment gateway of the
                    service provider and is not supplied to Prompt Enterprises.
                    It is the sole responsibility of the User of the service to
                    ensure that the information entered in the relevant fields
                    are correct. It is recommended that you take and retain a
                    copy of the transaction for record keeping purposes, which
                    might assist in resolution of any disputes that may arise
                    out or usage of the service.{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    The Applicant agrees, understands and confirms that his/ her
                    personal data including without limitation details relating
                    to Credit Card, Debit card, UPI, Wallets or Net Banking
                    transmitted over the Internet may be susceptible to misuse,
                    hacking, theft and/ or fraud and that Prompt Enterprises or
                    the Payment Service Provider(s) have no control over such
                    matters.{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    The service is provided using a payment gateway service
                    provider through a secure website. However, Prompt
                    Enterprises does not give any assurance, that the
                    information so provided online by a user is secured or may
                    be read or intercepted by a third party. Prompt Enterprises
                    does not accept or assume any liability in the event of such
                    unauthorized interception, hacking or other unauthorized
                    access to information provided by a user of the service.{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Prompt Enterprises shall not be liable for any inaccuracy,
                    error or delay in, or omission of (a) any data, information
                    or message, or (b) the transmission or delivery of any such
                    data, information or message; or (c) any loss or damage
                    arising from or occasioned by any such inaccuracy, error,
                    delay or omission, non-performance or interruption in any
                    such data, information or message. Under no circumstances
                    shall the Prompt Enterprises, its employees, directors, and
                    its third party agents involved in processing, delivering or
                    managing the Services, be liable for any direct, indirect,
                    incidental, special or consequential damages, or any damages
                    whatsoever, including punitive or exemplary arising out of
                    or in any way connected with the provision of or any
                    inadequacy or deficiency in the provision of the Services or
                    resulting from unauthorized access or alteration of
                    transmissions of data or arising from suspension or
                    termination of the Service.{" "}
                  </p>
                </div>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    The Applicant agrees that Prompt Enterprises or any of its
                    employees will not be held liable By the Applicant for any
                    loss or damages arising from your use of, or reliance upon
                    the information contained on the Website, or any failure to
                    comply with these Terms and Conditions where such failure is
                    due to circumstance beyond Prompt Enterprises’s reasonable
                    control.{" "}
                  </p>
                </div>
                <h3>DEBIT/CREDIT CARD, BANK ACCOUNT DETAILS</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    The Applicant agrees that the debit/credit card details
                    provided by him/ her for use of the aforesaid Service(s)
                    must be correct and accurate and that the Applicant shall
                    not use a Debit/ credit card, that is not lawfully owned by
                    him/ her or the use of which is not authorized by the lawful
                    owner thereof. The Applicant further agrees and undertakes
                    to Provide correct and valid debit/credit card details.{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    The Applicant may pay his/ her application/initial fees to
                    Prompt Enterprises by using a debit/credit card or through
                    online banking account. The Applicant warrants, agrees and
                    confirms that when he/ she initiates a payment transaction
                    and/or issues an online payment instruction and provides
                    his/ her card / bank details:
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    The Applicant is fully and lawfully entitled to use such
                    credit / debit card, bank account for such transactions;
                  </p>
                </div>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    The Applicant is responsible to ensure that the card/ bank
                    account details provided by him/ her are accurate;{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    The Applicant authorizes debit of the nominated card/ bank
                    account for the Payment of fees selected by such Applicant
                    along with the applicable Fees.
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    The Applicant is responsible to ensure that sufficient
                    credit is available on the nominated card/ bank account at
                    the time of making the payment to permit the Payment of the
                    dues payable or fees dues selected by the Applicant
                    inclusive of the applicable Fee.
                  </p>
                </div>
                <h3>NO WARRANTY</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    No warranty, representation or guarantee, express or
                    implied, is given by the Prompt Enterprises in respect of
                    the operation of the service.
                  </p>
                </div>
                <h3>DISCLAIMER AND LIMITATION OF LIABILITY</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Prompt Enterprises does not accept liability for any damage,
                    loss, cost (including legal costs), expenses, indirect
                    losses or consequential damage of any kind which may be
                    suffered or incurred by the User from the use of this
                    service.
                  </p>
                </div>
                <h3>GOVERNING LAW</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    These terms and conditions are governed by the laws of India
                    and the competent courts at Nashik (Maharashtra, India.)
                    shall have exclusive jurisdiction.
                  </p>
                </div>
                <h3>DISPUTE RESOLUTION</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Any dispute arising in relation to the terms and conditions
                    will be submitted to a sole arbitrator appointed by Prompt
                    Enterprises under the provisions of the Arbitration and
                    Conciliation Act, 1996 and rules framed there under. The
                    venue of Arbitration shall be at Nashik (Maharashtra,
                    India.).
                  </p>
                </div>
                <h3>REFUND/CANCELLATION POLICY</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Once payment made will not be refunded under any
                    circumstances.
                  </p>
                </div>
                <h3>PRIVACY POLICY</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    The information provided by the user on this website shall
                    be kept confidential and shall not be shared with anyone.
                    The DATA will neither be made accessible to third parties
                    nor transferred in any form or manner unless required by
                    law.
                  </p>
                </div>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Prompt Enterprises may share your personal information with
                    our bankers for clearing necessary fees payments. This
                    information shall be strictly up to the legal permissible
                    limit and not exceeding.
                  </p>
                </div>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Online Payment system purpose to provide flexibility and
                    conform to its continuing subscriber by offering option to
                    pay online using various payment methods.
                  </p>
                </div>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    It is sole responsibility of subscriber to pick online
                    payment option. Prompt Enterprises do not recommend/
                    prefer/promote any particular method.
                  </p>
                </div>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    If payment is made by means of a card that you do not
                    personally own, the permission of the card owner must always
                    be obtained to make payments using the card. In using the
                    system you confirm that you have such permission.
                  </p>
                </div>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Information related to payments using debit or credit card
                    is not accessed or stored by the Prompt Enterprises.
                  </p>
                </div>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    No Warranty: The information and materials contained in this
                    site including, graphics, links or other items are provided
                    as on “As Is” and “As Available” basis by the Prompt
                    Enterprises which organized and tries to provide information
                    accurately and expressly disclaims liability for error or
                    omission in this information and materials. No warranty of
                    any kind, implied, express or statutory shall be given by
                    the Prompt Enterprises shall not be limited to the warranty
                    of fitness for a particular purpose and freedom from
                    computer virus is given in conjunction with the information
                    and materials.
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Limitation of Liability: In no event, Prompt Enterprises
                    will be liable for any damage direct or indirect losses or
                    expenses arising in connection with site or use thereof
                    inability to use by any person delay of operation or
                    transaction, computer virus etc.
                  </p>
                </div>
                <h3>SECURITY</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    All payment details which are entered through this payment
                    gateway are encrypted when the Subscriber, or third party
                    making payment, enters them. The site is secure and uses
                    128-bit encryption to offer secure communications by
                    encrypting all data to and from the site The Prompt
                    Enterprises shall not be liable for any failure by the
                    subscriber or third party making payment of subscription to
                    properly protect data from being seen on their screen by
                    other persons or otherwise obtained by such other persons,
                    during the Online Payment process or in respect of any
                    omission to provide accurate information in the Course of
                    the Online Payment Process.
                  </p>
                </div>
                <h3>VARIATIONS TO THE TERMS AND CONDITIONS</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    The Prompt Enterprises reserves the right to vary these
                    Terms and Conditions from time to time and the current
                    version will be that published on this website.
                  </p>
                </div>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    We reserve the right to decline the acceptance of an online
                    payment if your account is in default for any reason. Prompt
                    Enterprises may also make additions/deletions/alteration to
                    the services offered, at its sole discretion. We reserves
                    the right to withdraw the service at any time at its
                    discretion. We retain the right to change the terms and
                    conditions for Online Payments, without any prior notice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default TermsCondition;
