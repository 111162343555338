import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { FaPlay } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const VideoSectionOne = ({ videoSrc }) => {
  const [toggler, setToggler] = useState(false);
  const getVideoId = (string) => {
    let idx = 0;
    if (string?.includes("v=")) {
      idx = string?.indexOf("v=") + 2;
    } else if (string?.includes("be/")) {
      idx = string?.indexOf("be/") + 3;
    }
    const vid = string?.substring(idx, string.length);
    return vid;
  };
  return (
    <>
      <div className="eduvibe-home-four-video edu-video-area edu-section-gap video-style-2">
        <div className="container eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-6">
              <div className="pr--75 pr_lg--30 pr_md--0 pr_sm--0">
                <div className="thumbnail video-popup-wrapper">
                  <img
                    className="radius-small"
                    // src={`/images/videopopup/video-popup-bg-03.jpg`}
                    src={`https://img.youtube.com/vi/${getVideoId(
                      videoSrc[0]
                    )}/0.jpg`}
                    alt="Video PopUp Thumb"
                  />
                  <button
                    className="video-play-btn with-animation position-to-top video-popup-activation color-secondary size-80"
                    onClick={() => setToggler(!toggler)}
                  >
                    <span>
                      <FaPlay className="play-icon" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner " style={{ marginTop: -40 }}>
                <SectionTitle
                  // slogan = "About Us"
                  title="Practical GST Return Filing Course Using Simulation Software"
                />

                <div className="feature-list-wrapper mt-2">
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className="feature-list"
                    animateOnce={true}
                  >
                    <i className="icon-checkbox-circle-fill"></i>Become
                    Professional Accounts Manager
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className="feature-list"
                    animateOnce={true}
                  >
                    <i className="icon-checkbox-circle-fill"></i>Join Prompt’s
                    Institute of Business Accounting & Taxation.
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className="feature-list"
                    animateOnce={true}
                  >
                    <i className="icon-checkbox-circle-fill"></i>India’s No. 1
                    Institute to Provide Practical training using Simulation
                    Software.
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className="feature-list"
                    animateOnce={true}
                  >
                    <i className="icon-checkbox-circle-fill me-2"></i>Build
                    Expertise & Accelerate Your Learning with Certified GST
                    Return Filing Course Using Simulation Software
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>

          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="/images/shapes/shape-03-10.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="/images/shapes/shape-19-03.png" alt="Shape Thumb" />
            </div>
          </div>
        </div>
      </div>

      <FsLightbox
        toggler={toggler}
        sources={videoSrc}
        maxYoutubeVideoDimensions={{ width: 900, height: 550 }}
      />
    </>
  );
};

export default VideoSectionOne;