import React from "react";
import { Parallax } from "react-scroll-parallax";

const AboutUsThreeMission = () => {
  return (
    <div className="eduvibe-about-three-mission edu-mission-vision-area edu-section-gap border-bottom-1 bg-color-white">
      {/* <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="thumbnail mb--50">
                            <div className="shape-image image-1">
                                <Parallax speed={1} y={[0, 40]} tagOuter="figure">
                                    <img src="/images/mission/mission-gallery-01.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="shape-image image-2">
                                <Parallax speed={1} y={[0, -20]} tagOuter="figure">
                                    <img src="/images/mission/mission-gallery-02.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="shape-image image-3">
                                <Parallax speed={1} y={[0, 30]} tagOuter="figure">
                                    <img src="/images/mission/mission-gallery-03.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="shape-image image-4">
                                <Parallax speed={1} y={[0, -18]} tagOuter="figure">
                                    <img src="/images/mission/mission-gallery-04.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="animated-image shape-image-1">
                        <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                    <div className="shape shape-2">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div> */}

      <div className="container">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="our-mission">
              <div className="section-title text-start">
                {/* <span className="pre-title">Our Mission</span> */}
                <h3 className="title">Education Wing </h3>
                <p className="mt--30 mb--0">
                  <b>Prompt’s INSTITUE OF BUSINESS ACCOUNTING AND TAXATION</b>{" "}
                  is based at Dhule was founded on 1St April 1996 , And IS ONE
                  OF THE BEST INSTITUTE IN CAREER COMMERCE IN INDIA providing
                  practical and career Oriented Training since inception.
                  Everyone knows the importance of the Accounting profession.
                  This profession belongs to every type of business
                  organization. Every business establishment always required an
                  account professional. Bookish knowledge is not enough to do
                  practical work in any type of business organization. With the
                  unique concept of Self learning Practical book and Simulation
                  software. We trained Student in just 6 months with 100%
                  perfection. People are pursuing various degrees with the hope
                  of securing a job. Most of them however remain unemployed
                  because employers not finding them suitable to fill the gap.
                  Prompt’s INSTITUTE OF BUSINESS ACCOUNTING & TAXATION takes
                  this humble effort towards bridging the gap. Prompt’s
                  INSTITUTE is the place which provides special training and
                  guidance and converts ordinary accountant into Professional
                  Accounts Manager (PAM). The job training courses offered by
                  Prompt’s INSTITUTE emphasize adequately on practical knowledge
                  to bring smiles to the employers as well as the candidates
                  seeking a job after studying at Prompt’s INSTITUTE.
                  <br />
                  <br />
                  We create Professional Accountants, who are not only ready to
                  handle today’s complexities of taxation & accounting but also
                  tomorrow’s challenges in the world of finance. Our PAMs are
                  having edge and advantage in so many aspects on other regular
                  accountants. They are complete and Professional Accounts
                  Manager. We Are Probably the Only One Institute to Provide
                  100% job oriented Professional Courses with practical approach
                  for better careers of young minds in companies thru Real Life
                  Corporate Projects.
                  <br />
                  <br />
                  Each and every student is been nurtured in a unique way of
                  giving simulation of real-life corporate accounting & finance
                  work is so effective that after completion of the courses they
                  will be ready to face any kind of challenge in the practical
                  work field. Practical knowledge of what actually required at
                  work is crucial for the grooming of competent Certified
                  Professionals and practical knowledge can be imparted only by
                  practitioner in real life situations.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="our-vision">
              <div className="section-title text-start">
                {/* <span className="pre-title">Our Vision</span> */}
                <h3 className="title">Software Wing </h3>
                <p className="mt--30 mb--0">
                  Prompt is renowned Enterprise Tally Sales Training and
                  Implementation Partner catering across the Maharashtra. We
                  help our clients to understand their business process by our
                  strong communication and information system. Our depth product
                  knowledge helps customer to access their requirement easily.
                  Prompt with a big experience in the training and
                  implementation offers SME a complete solutions. which includes
                  consultancy, training, design, development and implementation.
                  Our easy way to explain the business process requirement makes
                  customer happy. Prompt caters customers in various sectors
                  such as Manufacturing, Trading, Non- Trading with different
                  business verticals and Governments. Prompt is associated with
                  Tally Solutions for over 25 years. A strong 10000 + Customer
                  base in North Maharashtra Region. Prompt is also associated
                  with Tally Education since last 20 years.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsThreeMission;
