export const GET_OTP = "GET_OTP";
export const GET_DISTRICTS = "GET_DISTRICTS";
export const GET_DISTRICTS_FAIL = "GET_DISTRICTS_FAIL";
export const GET_STATES = "GET_STATES";
export const GET_STUDENT_CERTIFICATE = "GET_STUDENT_CERTIFICATE";
export const GET_ONLINE_COURSES = "GET_ONLINE_COURSES";
export const GET_BOOKS = "GET_BOOKS";
export const GET_EXAMS = "GET_EXAMS";
export const GET_GST = "GET_GST";
export const GET_INVOICE = "GET_INVOICE";
