import * as actionTypes from "../actions/actionTypes";

const initialState = {
  districts: [],
  states: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DISTRICTS:
      return {
        ...state,
        districts: action.districts,
      };
    case actionTypes.GET_DISTRICTS_FAIL:
      return {
        ...state,
        districts: [],
      };
    case actionTypes.GET_STATES:
      return {
        ...state,
        states: action.states,
      };

    default:
      return state;
  }
};

export default reducer;
