import React from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../common/breadcrumb/BreadcrumbOne";

const PrivacyPolicy = () => {
  return (
    <>
      <SEO title="Privacy Policy" />
      <Layout>
        <BreadcrumbOne
          title="Privacy Policy"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="privacy-policy"
        />
        <section
          className="pt-8 pt-md-11 pb-8 pb-md-14"
          style={{ marginTop: 50, marginBottom: 50 }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <p className="font-size-lg text-gray-800 mb-6 mb-md-8">
                  We collect the email addresses of those who communicate with
                  us via email, aggregate information on what pages consumers
                  access or visit, and information volunteered by the consumer
                  (such as survey information and/or site registrations). The
                  information we collect is used to improve the content on the
                  Prompt Enterprises website and the quality of our service, and
                  is not shared with or sold to other organizations for
                  commercial purposes, except to provide products or services
                  you&apos;ve requested, when we have your permission, or under
                  the following circumstances:{" "}
                </p>
                <p className="text-gray-800">
                  We may transfer information about you if Prompt Enterprises is
                  acquired by or merged with another company. In this event,
                  Prompt Enterprises will notify you before information about
                  you is transferred and becomes subject to a different privacy
                  policy.
                </p>

                <h3>Information Gathering and Usage</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    When you register for Prompt Enterprises we ask for
                    information such as your name, company name, email address,
                    billing address and credit card information. Members who
                    sign up for the free account are not required to enter a
                    credit card.{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Prompt Enterprises uses collected information for the
                    following general purposes: products and services provision,
                    billing, identification and authentication, services
                    improvement, contact and research.{" "}
                  </p>
                </div>

                <h3>Data Storage</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Prompt Enterprises uses third party vendors and hosting
                    partners to provide the necessary hardware, software,
                    networking, storage, and related technology required to run
                    Prompt Enterprises. Although Prompt Enterprises owns the
                    code, databases, and all rights to the Prompt Enterprises
                    application, you retain all rights to your data.
                  </p>
                </div>
                <h3>Disclosure</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Prompt Enterprises may disclose personally identifiable
                    information under special circumstances, such as to comply
                    with subpoenas or when your actions violate the Terms of
                    Service.
                  </p>
                </div>
                <h3>Changes</h3>

                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                  <p className="text-gray-800">
                    Prompt Enterprises may periodically update this policy. We
                    will notify you about significant changes in the way we
                    treat personal information by sending a notice to the
                    primary email address specified in your Prompt Enterprises
                    primary account holder account or by placing a prominent
                    notice on our site.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
