import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import ViewCertificate from "./ViewCertificate";
import ViewGstCertificate from "./ViewGstCertificate";
import * as actions from "../redux/actions";

const Verify = (props) => {
  const [certNo, setCertNo] = useState();
  const [submitted, setSubmitted] = useState(false);
  const { certificate, getCertificate } = props;
  useEffect(() => {
    console.log(certificate?.ProductName);
  }, [certificate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await getCertificate(certNo);
    setSubmitted(true);
  };

  return (
    <div>
      {/* <div style={{ margin: "auto", width: 500 }}> */}
      <h5 style={{ textAlign: "center", marginTop: 10 }}>
        Please Enter certificate number to verify your certificate
      </h5>
      {/* </div> */}
      <div
        className="login-form-box"
        style={{ margin: 15, width: 300, margin: "auto" }}
      >
        <form
          className="login-form"
          action="#"
          style={{ display: "flex", alignItems: "center", gap: 15 }}
          onSubmit={handleSubmit}
        >
          <div className="input-box">
            <input
              type="text"
              placeholder="CertificateNo."
              name="certNo"
              //   value={certNo || ""}
              onBlur={(e) => {
                let no = e.target.value.substring(
                  e.target.value.indexOf("/") + 1,
                  e.target.value.length
                );
                setCertNo(no);
              }}
              required
              // disabled={otpVisible}
            />
          </div>

          <button
            className="rn-btn edu-btn"
            style={{ height: 35, lineHeight: 0 }}
            type="submit"
          >
            <span>View</span>
          </button>
        </form>
      </div>
      {submitted && (
        <>
          {window.location.hash.includes("verifygst") ? (
            <>
              {certificate?.ProductName == "GST test" ? (
                <ViewGstCertificate certNo={certNo} />
              ) : (
                <h3 style={{ color: "red" }}>Invalid GST Certificate</h3>
              )}
            </>
          ) : (
            <>
              {certificate?.ProductName != "GST test" ? (
                <ViewCertificate certNo={certNo} />
              ) : (
                <h3 style={{ color: "red" }}>Invalid Certificate</h3>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    certificate: state.exam.studentCertificate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCertificate: (certno) => dispatch(actions.GetStudentCertificate(certno)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
