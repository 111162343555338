import React, { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import ContactUsForm from "../../components/contact/ContactUsForm";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { Alert, Snackbar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Result = () => {
  return (
    <p className="success-message">
      Thanks for your application. We will contact you soon.
    </p>
  );
};

const ApplyFranchise = (props) => {
  const [result, setResult] = useState(false);
  const [state, setState] = useState({ isActive: true, userStatus: 0 });
  const [snackMsg, setSnackMsg] = useState("");
  const [snackType, setSnackType] = useState("");
  const [loading, setLoading] = useState(false);
  const [district, setDistrict] = useState();
  const [myState, setMyState] = useState();

  const { applyCoordinator, applyFranchise, emailExist } = props;
  const {
    getStates,
    getDistricts,
    statesList,
    getDistrictByState,
    districtList,
  } = props;

  useEffect(() => {
    // getDistricts();
    getStates();
  }, []);

  const {
    franchiseName,
    mobileNo,
    coordinatorName,
    address,
    emailId,
    whatsAppNo,
    cpMobileNo,
    addressLine1,
    addressLine2,
    taluka,
    city,
    pinCode,
    contactPerson,
    gst,
    pan,
  } = state;

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await emailExist(emailId);
    } catch (error) {
      console.log(error);
      if (error == "exists")
        setSnackMsg("It seems this email is already registered with us");
      else setSnackMsg("Something went wrong!");

      setSnackType("error");
      handleClick();
      return;
    }

    let values = { ...state };
    values.CpMobileNo = mobileNo;
    console.log(values);
    try {
      await applyFranchise(values);
      setResult(true);
      setState({});
      setMyState("");
      setDistrict("");
      setSnackMsg("Application submitted successfully!");
      setSnackType("success");
      handleClick();
    } catch (error) {
      console.log(error);
      setSnackMsg("Something went wrong!");
      setSnackType("error");
      handleClick();
    }
    setLoading(false);
  };

  setTimeout(() => {
    setResult(false);
  }, 60000);

  // const handleDistrictChange = (event) => {
  //   event.persist();
  //   let dist = districtList.filter(
  //     (district) => district.DistrictTitle === event.target.value
  //   );
  //   if (dist[0]) {
  //     setState({ ...state, districtId: dist[0].DistrictId });
  //   }
  // };
  // const handleStatesChange = async (event) => {
  //   event.persist();
  //   let st = statesList.filter(
  //     (item) => item.StateTitle === event.target.value
  //   );
  //   await getDistrictByState(st[0]?.StateId);
  //   setState({ ...state, stateId: st[0]?.StateId });
  // };

  useEffect(() => {
    let dist = districtList.filter((item) => item.DistrictTitle === district);
    setState({ ...state, DistrictId: dist[0]?.DistrictId });
  }, [district]);

  useEffect(() => {
    async function fetchData() {
      let st = statesList.filter((item) => item.StateTitle === myState);
      setState({ ...state, StateId: st[0]?.StateId, DistrictId: "" });
      await getDistrictByState(st[0]?.StateId);
      setDistrict("");
    }
    fetchData();
  }, [myState]);

  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  return (
    <>
      <SEO title="Apply for Franchise" />
      <Layout data-testid='hello'>
        <BreadcrumbOne
          title="Apply for Franchise"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Apply for Franchise"
        />
        <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">Get in Business with Us</span>
                  <h3 className="title">Apply for Franchise</h3>
                </div>
              </div>
            </div>
            <div
              className="row g-5 mt--20"
              style={{ width: "80%", margin: "auto" }}
            >
              <form
                className="rnt-contact-form rwt-dynamic-form row"
                action=""
                onSubmit={sendEmail}
              >
                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <select name="type" onChange={handleChange}>
                      <option selected disabled>
                        Reseller Type
                      </option>
                      <option>Franchise</option>
                      <option>District Coordinator</option>
                    </select>
                  </div>
                </div> */}
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="text"
                      className="form-control form-control-lg"
                      name="franchiseName"
                      placeholder="Franchise Name*"
                      value={franchiseName || ""}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="email"
                      className="form-control form-control-lg"
                      name="emailId"
                      placeholder="Email*"
                      value={emailId || ""}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="text"
                      className="form-control form-control-lg"
                      name="pan"
                      placeholder="PAN (optional)"
                      value={pan || ""}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="number"
                      className="form-control form-control-lg"
                      name="mobileNo"
                      value={mobileNo || ""}
                      placeholder="Mobile No."
                      required
                      min="1000000000"
                      max="9999999999"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Enter 10 digit number")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="number"
                      className="form-control form-control-lg"
                      name="whatsAppNo"
                      value={whatsAppNo || ""}
                      placeholder="Whatsapp No."
                      required
                      min="1000000000"
                      max="9999999999"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Enter 10 digit number")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="number"
                      className="form-control form-control-lg"
                      name="gst"
                      placeholder="GST no. (optional)"
                      value={gst || ""}
                      min="100000000000000"
                      max="999999999999999"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Enter 15 digit number")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="text"
                      className="form-control form-control-lg"
                      name="addressLine1"
                      value={addressLine1 || ""}
                      placeholder="Address Line 1"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="text"
                      className="form-control form-control-lg"
                      name="addressLine2"
                      value={addressLine2 || ""}
                      placeholder="Address Line 2"
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  {/* <div className="form-group">
                    <select name="type" onChange={handleChange}>
                      <option selected disabled>
                        Country
                      </option>
                    </select>
                  </div> */}

                  <div className="form-group">
                    <select
                      name="state"
                      // onBlur={handleStatesChange}
                      value={myState}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setMyState(e.target.value);
                      }}
                    >
                      <option selected disabled value="">
                        State
                      </option>
                      {statesList.map((item) => (
                        <option>{item.StateTitle}</option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <select
                      placeholder="District"
                      defaultValue={"District"}
                      name="district"
                      // onBlur={handleDistrictChange}
                      onChange={(e) => setDistrict(e.target.value)}
                      // value={district}
                    >
                      <option selected disabled value="">
                        District
                      </option>
                      {districtList.length > 0 &&
                        districtList.map((item) => (
                          <option>{item.DistrictTitle}</option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="text"
                      className="form-control form-control-lg"
                      name="city"
                      placeholder="City"
                      value={city || ""}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="text"
                      className="form-control form-control-lg"
                      name="taluka"
                      placeholder="Taluka"
                      value={taluka || ""}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="number"
                      className="form-control form-control-lg"
                      name="pinCode"
                      value={pinCode || ""}
                      placeholder="Pincode"
                      required
                      min="100000"
                      max="999999"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Enter 6 digit number")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  {!loading ? (
                    <button className="rn-btn edu-btn w-100" type="submit">
                      <span>Apply</span>
                      <i className="icon-arrow-right-line-right"></i>
                    </button>
                  ) : (
                    <button className="rn-btn edu-btn w-100" type="submit">
                      <CircularProgress />
                    </button>
                  )}
                </div>
                {result ? (
                  <div className="form-group">
                    <Result />
                  </div>
                ) : null}
              </form>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-2">
                <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-3">
                <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
        </div>

        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            sx={{ m: 1 }}
            severity={snackType}
            variant="filled"
          >
            {snackMsg}
          </Alert>
        </Snackbar>

        {/* <div className="edu-contact-map-area edu-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="google-map alignwide">
                  <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1114.617608948499!2d73.74915253208648!3d20.003821623750902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeb8736b45c4d%3A0xa0156cf23367fba7!2sTechbona%20Infomatics%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1663999008594!5m2!1sen!2sin"
                    // width="600"
                    height="500"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    tabIndex="0"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  // console.log(state.franchise.franchiseDetails);
  // console.log(state)
  return {
    districtList: state.districtAndState.districts,
    statesList: state.districtAndState.states,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    applyFranchise: (data) => dispatch(actions.ApplyFranchise(data)),
    emailExist: (email) => dispatch(actions.CheckFranchiseEmail(email)),
    getDistricts: () => dispatch(actions.GetDistrict()),
    getDistrictByState: (stateId) =>
      dispatch(actions.GetDistrictByState(stateId)),
    getStates: () => dispatch(actions.GetStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyFranchise);
