import * as actionTypes from "./actionTypes";
import axios from "../axios";

export const GetStudentCertificate = (certNo) => {
  return async (dispatch) => {
    await axios
      .get(`/Student/Certificate?sr=${certNo}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_STUDENT_CERTIFICATE,
          studentCertificate: response.data,
        });
      })
      .catch((error) => {});
  };
};
export const GetStudentMarks = (studentCourseId) => {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios
      .get(`/Student/ExamMarkSheet/${studentCourseId}`)
      .then((response) => {
        // dispatch({
        //   type: actionTypes.GET_STUDENT_MARKS,
        //   studentMarks: response.data,
        // });
      })
      .catch((error) => {});
  };
};
