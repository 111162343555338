import * as actionTypes from "./actionTypes";
import axios from "../axios";

export const RegisterStudent = (data) => {
  return async (dispatch) => {
    // const accessToken = window.localStorage.getItem("accessToken");
    let config = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    };
    await axios
      .post(`/Website/SaveStudentLead`, data, config)
      .then((response) => {
        console.log(response.data);
        // dispatch({
        //   type: actionTypes.GET_OTP,
        //   otp: response.data.Otp,
        // });
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
};
export const GetStudentOtp = (data) => {
  return async (dispatch) => {
    // const accessToken = window.localStorage.getItem("accessToken");
    let config = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    };
    await axios
      .post(`/Website/GetStudentOtp`, data, config)
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: actionTypes.GET_OTP,
          otp: response.data.Otp,
        });
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
};


// export const CheckStudentEmail = (email) => {
//   return async (dispatch) => {
//     // const accessToken = window.localStorage.getItem("accessToken");
//     let config = {
//       headers: {
//         "Content-Type": "application/json",
//         // Authorization: `Bearer ${accessToken}`,
//       },
//     };
//     await axios
//       .post(`/Website/CheckStudentEmail/${email}`, config)
//       .then((response) => {
//         console.log(response.data);
//         // dispatch({
//         //   type: actionTypes.STUDENT_EMAIL_EXISTS,
//         //   emailExists: response.data,
//         // });
//       })
//       .catch((error) => {
//         console.log(error);
//         throw error;
//       });
//   };
// };

export const CheckStudentEmail = (email) => {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    let config = {
      headers: {
        // Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios
      .get(`/Website/CheckStudentEmail/${email}`, config)
      .then((response) => {
        console.log(response.data);
        if (response.data.IsEmailExist) {
          throw "exists";
        }
      });
  };
};
