import React, { useState } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import LoginForm from "../../components/form/LoginForm";
import RegisterForm from "../../components/form/RegisterForm";
import { Alert, Snackbar } from "@mui/material";

const LoginRegister = () => {
  const [snackMsg, setSnackMsg] = useState("");
  const [snackType, setSnackType] = useState("");
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState("Register");

  function handleClick() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  const snack = (msg, type) => {
    setSnackMsg(msg);
    setSnackType(type);
    handleClick();
  };

  const handleVisible = (type) => {
    setVisible(type);
  };
  return (
    <>
      <SEO title={visible} />
      <Layout>
        <BreadcrumbOne
          title={visible}
          rootUrl="/"
          parentUrl="Home"
          currentUrl={visible}
        />

        <div className="login-register-page-wrapper edu-section-gap bg-color-white">
          <div className="container checkout-page-style">
            <div className="row g-5">
              <div
                className="col-lg-6"
                style={{
                  margin: "auto",
                  display: visible === "Login" ? "" : "none",
                }}
              >
                <LoginForm snack={snack} handleVisible={handleVisible} />
              </div>
              <div
                className="col-lg-6"
                style={{
                  margin: "auto",
                  display: visible === "Register" ? "" : "none",
                }}
              >
                <RegisterForm snack={snack} handleVisible={handleVisible} />
              </div>
            </div>
          </div>
        </div>

        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            sx={{ m: 1 }}
            severity={snackType}
            variant="filled"
          >
            {snackMsg}
          </Alert>
        </Snackbar>
      </Layout>
    </>
  );
};

export default LoginRegister;
