import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../redux/axios";
import { Alert, Snackbar } from "@mui/material";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import PurchaseDialog from "./PurchaseDialog";

const CourseTypeOne = ({
  data,
  classes,
  idKey,
  nameKey,
  productType,
  title,
  ...props
}) => {
  const purchaseDialogRef = useRef(null);

  const { getDistrictByState, statesList, districtList, assignProduct } = props;

  const launchModal = () => {
    console.log(purchaseDialogRef.current);
    if (purchaseDialogRef.current) purchaseDialogRef.current.handleClick();
  };

  return (
    <>
      <div
        className={`edu-card card-type-3 radius-small ${
          classes ? classes : ""
        }`}
      >
        <div className="inner">
          <div className="thumbnail">
            <img className="w-100" src={data.SrcUrl} alt="Course Thumb" />
          </div>
          <div className="content" style={{ padding: "10px 20px" }}>
            <h6 className="title">{data[nameKey]}</h6>
            <div className="card-bottom">
              <div className="price-list price-style-02">
                {data.Price === "0" ? (
                  <div className="price current-price">Free</div>
                ) : (
                  <div className="price current-price">₹{data.Price}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card-hover-action">
          <div className="hover-content">
            <div className="content-top"></div>

            <h6 className="title">{data[nameKey]}</h6>

            {/* <p className="description">{excerpt}</p> */}

            <div className="price-list price-style-02">
              {data.Price === "0" ? (
                <div className="price current-price">Free</div>
              ) : (
                <div className="price current-price">₹{data.Price}</div>
              )}
            </div>

            <div className="read-more-btn">
              <Link
                className="edu-btn btn-medium btn-white"
                // to={process.env.PUBLIC_URL + `/course-details-two/${data[idKey]}`}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  launchModal();
                }}
              >
                Purchase Now<i className="icon-arrow-right-line-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <PurchaseDialog
        data={data}
        idKey={idKey}
        nameKey={nameKey}
        productType={productType}
        title={title}
        getDistrictByState={getDistrictByState}
        statesList={statesList}
        districtList={districtList}
        assignProduct={assignProduct}
        ref={purchaseDialogRef}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    districtList: state.districtAndState.districts,
    statesList: state.districtAndState.states,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    assignProduct: (data) => dispatch(actions.AssignWebsiteProduct(data)),
    getDistrictByState: (stateId) =>
      dispatch(actions.GetDistrictByState(stateId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseTypeOne);
