import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import AboutFive from "../../components/about/AboutFive";
import LogoOne from "../../components/logos/LogoOne";
import HomeFiveProgress from "../../components/home-five/HomeFiveProgress";
import AboutUsThreeMission from "../../components/about-us-three/AboutUsThreeMission";
import AboutUsThreeInstructor from "../../components/about-us-three/AboutUsThreeInstructor";
import CounterUpOne from "../../components/counterup/CounterUpOne";
import TestimonialSectionTwo from "../../components/testimonial-section/TestimonialSectionTwo";

const AboutUsThree = () => {
  return (
    <>
      <SEO title="About Us" />
      <Layout>
        <BreadcrumbOne
          title="About Us"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="About Us"
        />

        <AboutUsThreeMission />
        <AboutFive wrapperClass="edu-section-gapTop" />

        {/* <LogoOne separator="disable" /> */}

        {/* <HomeFiveProgress /> */}

        <CounterUpOne />
        <br />
        <br />
        {/* <AboutUsThreeInstructor /> */}

        {/* <div style={{ hieght: 50 }}></div> */}
        {/* <TestimonialSectionTwo classes="counterup-overlay-top" /> */}
      </Layout>
    </>
  );
};

export default AboutUsThree;
