import React from "react";

const LoginForm = (props) => {
  const { handleVisible, snack } = props;

  return (
    <div className="login-form-box">
      <h3 className="mb-30">Login</h3>
      <form className="login-form" action="#">
        <div className="input-box mb--30">
          <input type="text" placeholder="Username or Email" />
        </div>
        <div className="input-box mb--30">
          <input type="password" placeholder="Password" />
        </div>
        <div className="comment-form-consent input-box mb--30">
          <input id="checkbox-1" type="checkbox" />
          <label htmlFor="checkbox-1">Remember Me</label>
        </div>
        <button className="rn-btn edu-btn w-100 mb--30" type="submit">
          <span>Login</span>
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="input-box">
            <a href="#" className="lost-password">
              Lost your password?
            </a>
          </div>
          <div
            className="input-box"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p style={{ marginBottom: 0 }}>Dont have an account?</p>
            <p
              style={{ marginBottom: 0, cursor: "pointer" }}
              className="lost-password"
              onClick={() => handleVisible("Register")}
            >
              Sign Up
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
