import * as actionTypes from "./actionTypes";
import axios from "../axios";

export const ApplyFranchise = (data) => {
  return async (dispatch) => {
    // const accessToken = window.localStorage.getItem("accessToken");
    let config = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    };
    await axios
      .post(`/Website/SaveFranchiseLead`, data, config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
};

export const ApplyCoordinator = (data) => {
  return async (dispatch) => {
    // const accessToken = window.localStorage.getItem("accessToken");
    let config = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    };
    await axios
      .post(`/Website/SaveDistrictCoordinator`, data, config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
};

export const CheckFranchiseEmail = (email) => {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    let config = {
      headers: {
        // Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios
      .get(`/Website/CheckFranchiseEmail/${email}`, config)
      .then((response) => {
        console.log(response.data);
        if (response.data.IsEmailExist) {
          throw "exists";
        }
      });
  };
};

export const CheckCoordinatorEmail = (email) => {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    let config = {
      headers: {
        // Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios
      .get(`/Website/CheckDistrictCoordinator/${email}`, config)
      .then((response) => {
        console.log(response.data);
        if (response.data.IsEmailExist) {
          throw "exists";
        }
      });
  };
};

// export const GetFranchise = () => {
//   return async (dispatch) => {
//     const accessToken = window.localStorage.getItem("accessToken");
//     let config = {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     };

//     await axios
//       .get(`/FranchiseDetails/GetAllFranchise`, config)
//       .then((response) => {
//         console.log(response.data);
//         dispatch({
//           type: actionTypes.GET_FRANCHISE_SUCCESS,
//           franchiseDetails: response.data,
//         });
//       })
//       .catch((error) => {
//         console.log(error);
//         throw error;
//       });
//   };
// };

// export const GetFranchiseById = (frId) => {
//   return async (dispatch) => {
//     const accessToken = window.localStorage.getItem("accessToken");
//     let config = {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     };

//     await axios
//       .get(`/FranchiseDetails/GetFranchiseById/${frId}`, config)
//       .then((response) => {
//         console.log(response.data);
//         dispatch({
//           type: actionTypes.GET_FRANCHISE_BY_ID,
//           frById: response.data,
//         });
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
// };

// export const CheckFranchiseEmail = (email) => {
//   return async (dispatch) => {
//     const accessToken = window.localStorage.getItem("accessToken");
//     let config = {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     };

//     await axios
//       .get(`/FranchiseDetails/CheckFranchiseEmail/${email}`, config)
//       .then((response) => {
//         console.log(response.data);
//         dispatch({
//           type: actionTypes.CHECK_FRANCHISE_EMAIL,
//           emailExist: response.data.IsEmailExist,
//         });
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
// };

// export const DeleteFranchise = (franchiseId) => {
//   return (dispatch) => {
//     const accessToken = window.localStorage.getItem("accessToken");
//     let config = {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${accessToken}`,
//       },
//     };

//     axios
//       .delete(`/FranchiseDetails/DeleteFranchise/${franchiseId}`, config)
//       .then((response) => {
//         console.log(response.data);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
// };

// export const UpdateFranchise = (franchise, franchiseId) => {
//   return async (dispatch) => {
//     const accessToken = window.localStorage.getItem("accessToken");
//     let config = {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${accessToken}`,
//       },
//     };

//     await axios
//       .put(
//         `/FranchiseDetails/UpdateFranchise/${franchiseId}`,
//         franchise,
//         config
//       )
//       .then((response) => {
//         console.log(response.data);
//         dispatch({
//           type: actionTypes.UPDATE_FRANCHISE,
//           updatedFranchise: response.data,
//         });
//       })
//       .catch((error) => {
//         console.log(error);
//         throw error;
//       });
//   };
// };
