import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
import HomeTwo from "./pages/homepages/HomeTwo";
import Error from "./pages/innerpages/Error";

// Import Css Here
import "./assets/scss/style.scss";
import "./App.css";
import ApplyCoordinator from "./pages/innerpages/ApplyCoordinator";
import ApplyFranchise from "./pages/innerpages/ApplyFranchise";
import ViewCertificate from "./pages/ViewCertificate";
import ViewInvoice from "./pages/ViewInvoice";
import Verify from "./pages/Verify";
import ViewGstCertificate from "./pages/ViewGstCertificate";
import TermsCondition from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/innerpages/ContactUs";
import AboutUsThree from "./pages/innerpages/AboutUsThree";
import LoginRegister from "./pages/innerpages/LoginRegister";

// import dotenv from "dotenv";

function App() {
  // dotenv.config();
  return (
    // <Router>
    <HashRouter>
      <ScrollToTop>
        <Routes>
          {/* <Route exact path="/" element={<LandingDemo />} /> */}
          <Route exact path="/" element={<HomeTwo />} />

          <Route path="*" element={<Error />} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/about-us-3"}`}
            element={<AboutUsThree />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/apply-coordinator"}`}
            element={<ApplyCoordinator />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/apply-franchise"}`}
            element={<ApplyFranchise />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/invoice/:invoiceNumber"}`}
            element={<ViewInvoice />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/verify/:certno"}`}
            element={<ViewCertificate />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/verifygst/:certno"}`}
            element={<ViewGstCertificate />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/verify"}`}
            element={<Verify />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/verifygst"}`}
            element={<Verify />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/terms-condition"}`}
            element={<TermsCondition />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
            element={<PrivacyPolicy />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/contact-us"}`}
            element={<ContactUs />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/login-register"}`}
            element={<LoginRegister />}
          />
        </Routes>
      </ScrollToTop>
    </HashRouter>
    // </Router>
  );
}

export default App;
