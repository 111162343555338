import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { useNavigate } from "react-router-dom";

const RegisterForm = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({ isActive: true, userStatus: 1 });
  const [otpVisible, setOtpVisible] = useState(false);
  const inputRef = useRef();
  const inputRef2 = useRef();

  const {
    firstName,
    lastName,
    mobileNo,
    emailId,
    otp,
    terms,
    password,
    cpassword,
    addressLine1,
  } = state;

  const {
    registerStudent,
    snack,
    studentOtp,
    emailExists,
    handleVisible,
    getOtp,
  } = props;

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password != cpassword) {
      snack("Passwords do not mactch", "error");
      return;
    }

    try {
      await emailExists(emailId);
    } catch (error) {
      if (error == "exists") snack("Email Id already Registered", "error");
      else snack("Something went wrong!", "error");

      return;
    }

    if (terms) {
      try {
        await getOtp(state);
        setOtpVisible(true);
      } catch (error) {
        console.log(error);
        snack("Something went wrong!", "error");
        return;
      }
    } else {
      snack("Please agree to the terms and conditions", "error");
      return;
    }
    inputRef.current.click();
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    if (otp === studentOtp) {
      try {
        await registerStudent(state);
        snack("Registration Successfull", "success");
        inputRef2.current.click();
        setState({});
        // handleVisible("Login");
        navigate("/");
      } catch (error) {
        console.log(error);
        snack("Something went wrong!", "error");
        return;
      }
    } else {
      snack("Invalid OTP", "error");
    }
  };

  return (
    <div className="login-form-box">
      <h3 className="mb-30">Register</h3>
      <form className="login-form" action="#" onSubmit={handleSubmit}>
        <div className="input-box mb--30">
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={firstName || ""}
            onChange={handleChange}
            required
            // disabled={otpVisible}
          />
        </div>
        <div className="input-box mb--30">
          <input
            type="text"
            placeholder="Last Name"
            value={lastName || ""}
            name="lastName"
            onChange={handleChange}
            required
            // disabled={otpVisible}
          />
        </div>
        <div className="input-box mb--30">
          <input
            type="email"
            placeholder="Email"
            value={emailId || ""}
            name="emailId"
            onChange={handleChange}
            required
            // disabled={otpVisible}
          />
        </div>
        <div className="input-box mb--30">
          <input
            onChange={handleChange}
            type="number"
            name="mobileNo"
            value={mobileNo || ""}
            placeholder="Phone"
            required
            min="1000000000"
            max="9999999999"
            onInvalid={(e) =>
              e.target.setCustomValidity("Enter 10 digit number")
            }
            onInput={(e) => e.target.setCustomValidity("")}
            // disabled={otpVisible}
          />
        </div>
        <div className="input-box mb--30">
          <textarea
            onChange={handleChange}
            type="text"
            name="addressLine1"
            value={addressLine1 || ""}
            placeholder="Address"
            required
            // disabled={otpVisible}
          />
        </div>

        <div className="input-box mb--30">
          <input
            // style={{ fontSize: 40 }}
            type="password"
            placeholder="Password"
            value={password || ""}
            name="password"
            onChange={handleChange}
            required
            style={{ fontSize: password?.length > 0 ? 33 : "" }}
            // disabled={otpVisible}
          />
        </div>
        <div className="input-box mb--30">
          <input
            // style={{ fontSize: 20 }}
            type="password"
            placeholder="Confirm Password"
            value={cpassword || ""}
            name="cpassword"
            onChange={handleChange}
            required
            style={{ fontSize: cpassword?.length > 0 ? 33 : "" }}
            // disabled={otpVisible}
          />
        </div>

        <div className="input-box mb--30">
          <input
            id="checkbox-2"
            type="checkbox"
            name="terms"
            value={terms || ""}
            // disabled={otpVisible}
            onChange={(e) => {
              setState({ ...state, terms: e.target.checked });
            }}
          />
          <label htmlFor="checkbox-2">
            I read & agree the terms & conditions.
          </label>
        </div>
        <button className="rn-btn edu-btn w-100" type="submit">
          <span>Register</span>
        </button>
        <div
          className="input-box mt--20"
          // style={{
          //   display: "flex",
          //   flexDirection: "column",
          //   alignItems: "center",
          // }}
        >
          <p style={{ marginBottom: 0 }}>Already have an account?</p>
          <p
            style={{ marginBottom: 0, cursor: "pointer" }}
            className="lost-password"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = process.env.REACT_APP_STUDENT_URL;
              // handleVisible("Login")
            }}
          >
            Sign In
          </p>
        </div>
      </form>

      <button
        type="button"
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModal"
        ref={inputRef}
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Verify OTP
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form className="login-form" action="#" onSubmit={handleSubmit2}>
              <div className="modal-body">
                <p>Please enter OTP recieved on your email</p>
                <div className="input-box mb--30">
                  <input
                    onChange={handleChange}
                    type="number"
                    name="otp"
                    value={otp || ""}
                    placeholder="OTP"
                    required
                    min="1000"
                    max="9999"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Enter 4 digit OTP")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="rn-btn edu-btn btn-secondary"
                  style={{ lineHeight: 2, height: 40 }}
                  type="button"
                  data-dismiss="modal"
                  ref={inputRef2}
                >
                  <span>Cancel</span>
                </button>

                <button
                  className="rn-btn edu-btn"
                  style={{ lineHeight: 2, height: 40 }}
                  type="submit"
                >
                  <span>Verify</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log(state.franchise.franchiseDetails);
  // console.log(state)
  return {
    studentOtp: state.student.otp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerStudent: (data) => dispatch(actions.RegisterStudent(data)),
    getOtp: (data) => dispatch(actions.GetStudentOtp(data)),
    emailExists: (email) => dispatch(actions.CheckStudentEmail(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
