import * as actionTypes from "../actions/actionTypes";

const initialState = {
  otp: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_OTP:
      return {
        ...state,
        otp: action.otp,
      };
    // case actionTypes.UPDATE_FRANCHISE:
    //   const tempFranchiseDetails = state.franchiseDetails.filter(
    //     (item) => item.FranchiseId !== action.updatedFranchise.FranchiseId
    //   );
    //   return {
    //     ...state,
    //     franchiseDetails: [...tempFranchiseDetails, action.updatedFranchise],
    //   };
    // case actionTypes.GET_FRANCHISE_BY_ID:
    //   return {
    //     ...state,
    //     frById: action.frById,
    //   };
    // case actionTypes.CHECK_FRANCHISE_EMAIL:
    //   return {
    //     ...state,
    //     emailExist: action.emailExist,
    //   };
    //
    default:
      return state;
  }
};

export default reducer;
