import React from "react";
import { styled } from "@mui/material";
import { Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Button, Icon, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { format } from "date-fns";
import * as actions from "../redux/actions";
import MyTable from "../components/MyTable";
import { useRef } from "react";
import ReactToPrint from "react-to-print";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const hsnCodes = [
  {
    prodType: 2,
    hsnCode: 4901,
    gst: 5,
  },
  {
    prodType: 3,
    hsnCode: 85238050,
    gst: 18,
  },
  {
    prodType: 4,
    hsnCode: 85238020,
    gst: 18,
  },
];

const columns = [
  { id: "ProductName", label: "Item", align: "center", minWidth: 170 },
  {
    id: "hsn",
    label: "HSN",
    align: "center",
    minWidth: 170,
    renderCell: (params) => <div>Hello</div>,
  },
  {
    id: "Rate",
    label: "Rate(₹)",
    format: (value) => value.toLocaleString("en-US"),
  },
  { id: "Quantity", label: "Qty" },

  {
    id: "TotalAmount",
    label: "Total(₹)",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const useStyles = makeStyles((theme) => ({
  smallHeading: {
    fontWeight: "bold",
    fontSize: 16,
    width: 90,
    marginRight: 15,
  },
  bigHeading: {
    fontWeight: "bold",
    fontSize: "medium",
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 7,
  },
  div2: {
    marginBottom: 11,
    width: 300,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  div3: {
    display: "flex",
    marginBottom: 5,
    // justifyContent: "space-between",
  },
  main: {
    // fontFamily: "'IBM Plex Mono', monospace",
    fontFamily: "sans-serif",
  },
  CompanyName: {
    fontSize: 23,
    fontWeight: "bold",
    marginBottom: 10,
  },
  bankHeading: {
    fontWeight: "bold",
    width: 110,
    marginRight: 5,
  },
}));

const ViewInvoice = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const componentRef = useRef();

  const { invoiceNumber } = useParams();

  const { getInvoice, invoice } = props;

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await getInvoice(invoiceNumber);
    }
    fetchData();
  }, [invoiceNumber]);

  useEffect(() => {
    if (invoice?.InvoiceItems?.length > 0) {
      let temp = invoice.InvoiceItems;
      const hsn = hsnCodes.find(
        (X) => X.prodType == invoice.ProductType
      )?.hsnCode;
      for (let index = 0; index < temp.length; index++) {
        temp[index] = { ...temp[index], id: temp[index].ItemId, hsn };
      }
      setTableData(temp);
    }
  }, [invoice]);

  return (
    <Container
      sx={{
        "& .MuiOutlinedInput-input": {
          boxSizing: "inherit",
        },
      }}
    >
      <Stack spacing={3}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <ReactToPrint
            trigger={() => (
              <StyledButton
                variant="contained"
                color="secondary"
                style={{ margin: 0 }}
              >
                Print Invoice
              </StyledButton>
              // <button>Print this out!</button>
            )}
            content={() => componentRef.current}
          />
        </div>

        <div
          ref={componentRef}
          style={{
            padding: 15,
            fontSize: 16,
            border: "1px solid lightgrey",
            // fontFamily: "'IBM Plex Mono', monospace"
          }}
        >
          <p className={classes.CompanyName}>Prompt Infotech</p>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
              paddingBottom: 10,
              borderBottom: "2px solid lightgray",
              lineHeight: 1.5,
            }}
          >
            <div>
              <div>23,Muncipal Colony,Nehru Nagar,</div>
              <div>Behind Deopur Church, Deopur,</div>
              <div>Dhule - 424 002 (M.S.) India.</div>
            </div>
            <div>
              <div>email : tally.prompt@gmail.com</div>
              <div>Landline : 02562 - 297178</div>
              <div>Cell : 94227 88512 / 93265 91204</div>
            </div>
          </div>
          {/* <div className={classes.div}>
                            <span className={classes.bigHeading}>Invoice Info</span>
                        </div> */}
          <div className={classes.div}>
            <span className={classes.bigHeading}>Invoice No.</span>
            {/* <div>
                                <span className={classes.smallHeading}>Order Status:</span>
                                <span>Delivered</span>
                            </div> */}
          </div>

          <div className={classes.div}>
            <span>
              # {invoice.InvoiceNumber?.toString().padStart(4, "0")}/
              {invoice?.CreatedDate &&
                format(new Date(invoice.CreatedDate), "yyyy")}
            </span>
            <div>
              <span className={classes.smallHeading}>Invoice Date :</span>
              <span>
                {invoice?.CreatedDate &&
                  format(new Date(invoice.CreatedDate), "dd MMM yyyy")}
              </span>
            </div>
          </div>
          <Divider style={{ marginBottom: 15 }} />

          <div className={classes.div}>
            <span className={classes.smallHeading}>Party Info</span>
            {/* <div>
                <span className={classes.smallHeading}>Order Type</span>
              </div> */}
          </div>
          <div className={classes.div}>
            <div style={{ display: "flex" }}>
              <div className={classes.smallHeading}>Name :</div>
              <span>
                {invoice?.StudentFirstName} {invoice?.StudentLastName}
              </span>
            </div>
            {/* <div>
                <span>Franchise</span>
              </div> */}
          </div>
          <div className={classes.div}>
            <div style={{ display: "flex" }}>
              <div className={classes.smallHeading}>Email:</div>
              <span>{invoice?.StudentEmail}</span>
            </div>
          </div>
          <div className={classes.div}>
            <div style={{ display: "flex" }}>
              <div className={classes.smallHeading}>Address:</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>{invoice?.StudentAddress}</span>
              </div>
            </div>
          </div>
          <div className={classes.div}>
            <div style={{ display: "flex" }}>
              <div className={classes.smallHeading}>Phone:</div>
              <span>{invoice?.StudentMobile}</span>
            </div>
          </div>

          <MyTable
            id="ItemId"
            height={350}
            rows={tableData}
            columns={columns}
            fontSize={16}
            // fontFamily={"'IBM Plex Mono', monospace"}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                marginTop: 15,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div className={classes.div2}>
                <span>Sub-total:</span>
                <span>
                  ₹ {invoice?.TotalPayable - invoice?.DeliveryCharges}
                </span>
              </div>
              <div className={classes.div2}>
                <span>
                  GST (
                  {hsnCodes.find((X) => X.prodType == invoice.ProductType)?.gst}
                  %):
                </span>
                <span>
                  ₹ {invoice?.DeliveryCharges ? invoice?.DeliveryCharges : 0}
                </span>
              </div>
              {/* <div className={classes.div2}>
                                <span>GST(%):</span>
                                <span>10</span>
                            </div> */}
              <div className={classes.div2}>
                <span
                  style={{ width: "auto" }}
                  className={classes.smallHeading}
                >
                  Invoice Value:
                </span>
                <span style={{ fontWeight: "bold" }}>
                  ₹ {invoice?.TotalPayable}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Stack>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    invoice: state.courses.invoice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onAddDc: (data) => dispatch(actions.AddDc(data)),
    // onGetDc: ()=> dispatch(actions.GetDc())
    getInvoice: (invoiceNumber) =>
      dispatch(actions.GetInvoiceByNumber(invoiceNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoice);
