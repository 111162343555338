import { Alert, Snackbar } from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import axios from "../../redux/axios";
import { useNavigate } from "react-router-dom";

const PurchaseDialog = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const { productType, data, nameKey, idKey, title } = props;
  const { assignProduct, statesList, getDistrictByState, districtList } = props;

  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const { email, password } = loginDetails;
  const [processing, setProcessing] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [studentId, setStudentId] = useState("");
  const [gst, setGst] = useState(0);
  const [district, setDistrict] = useState();
  const [myState, setMyState] = useState();
  const [state, setState] = useState({ isActive: true, userStatus: 0 });
  const { address, city, pinCode } = state;

  useEffect(() => {
    if (productType == 2) {
      setGst(5);
    } else {
      setGst(18);
    }
  }, [productType]);

  useEffect(() => {
    let dist = districtList.filter((item) => item.DistrictTitle === district);
    setState({ ...state, DistrictId: dist[0]?.DistrictId });
  }, [district]);

  useEffect(() => {
    async function fetchData() {
      let st = statesList.filter((item) => item.StateTitle === myState);
      setState({ ...state, StateId: st[0]?.StateId });
      if (st[0]?.StateId) await getDistrictByState(st[0].StateId);
    }
    fetchData();
  }, [myState]);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const [snackType, setSnackType] = useState("");

  const inputRef = useRef();
  const inputRef2 = useRef();

  const handleClick = (courseData) => {
    inputRef.current.click();
  };

  React.useImperativeHandle(ref, () => ({
    handleClick,
  }));

  function handleClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  }

  const handleSnack = (msg, type) => {
    setSnackMsg(msg);
    setSnackType(type);
    setSnackOpen(true);
  };

  const handleCancel = (e) => {
    setPageNo(1);
    setLoginDetails({ email: "", password: "" });
    setState({});
    setMyState("");
    setDistrict("");
    setStudentId("");
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    let config = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    };
    await axios
      .post(
        `/Login/ValidateWebsiteStudent`,
        {
          username: email,
          password: password,
          loginType: 0,
          productId: data[idKey],
        },
        config
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.ValidUser) {
          setPageNo(2);
          // setAddress(response.data.Address);
          const { Address, City, PinCode, StateId, DistrictId, DistrictTitle } =
            response.data;
          setState({
            address: Address,
            city: City,
            pinCode: PinCode,
            StateId,
            DistrictId,
          });
          const tempState = statesList.find((item) => item.StateId === StateId);

          setMyState(tempState?.StateTitle);
          setDistrict(DistrictTitle);

          setStudentId(response.data.StudentId);
        } else {
          handleSnack("Invalid Login Details", "error");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 400) {
          setPageNo(2);
          // handleSnack("Course already assigned", "error");
          // handleCancel();
          // inputRef2.current.click();
        } else {
          handleSnack("Something went wrong!", "error");
        }
      });
  };

  const makePayment = async (e) => {
    e.preventDefault();
    setProcessing(true);

    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      key_secret: process.env.REACT_APP_RAZORAY_KEYSECRET,
      amount: (data.Price + data.Price * gst * 0.01) * 100,
      currency: "INR",
      name: "GST",
      subscription: "for testing purpose",
      handler: async function (response) {
        // alert(response.razorpay_payment_id);

        // await paySuccess({
        //   amount: challanDetails.totalAmount,
        //   id: response.razorpay_payment_id,
        // });
        try {
          await assignProduct({
            studentId: studentId,
            productId: data[idKey],
            productType: productType,
            rate: data.Price,
            deliveryCharges: data.Price * gst * 0.01,
            paymentId: response.razorpay_payment_id,
            address: address,
            city: city,
            pinCode: pinCode,
            stateId: state.StateId,
            districtId: state.DistrictId,
            productName: data[nameKey],
          });
          setProcessing(false);
          handleSnack(
            "Payment Successful, Invoice has been sent to your email",
            "success"
          );
          handleCancel(e);
          inputRef2.current.click();
        } catch (error) {
          handleSnack("Something went wrong, please contact admin", "error");
        }
      },
      prefill: {
        email: email,
        // method: "netbanking",
        // bank: bankList.find((x) => x.name == bank).code,
      },
      theme: {
        color: "#3399cc",
        // hide_topbar: true,
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target={`#${data[idKey]}`}
        ref={inputRef}
      >
        Launch demo modal
      </button>
      <div
        className="modal fade"
        id={data[idKey]}
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {pageNo === 1 ? "Enter Login Details" : `Purchase ${title}`}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={handleCancel}
                ref={inputRef2}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {pageNo === 1 && (
              <div className="checkout-page-style login-register-page-wrapper">
                <div className="login-form-box" style={{ padding: 0 }}>
                  <form
                    className="login-form"
                    action="#"
                    onSubmit={handleSubmit1}
                  >
                    <div className="modal-body">
                      <div className="input-box mb--30">
                        <input
                          onChange={(e) =>
                            setLoginDetails({
                              ...loginDetails,
                              email: e.target.value,
                            })
                          }
                          type="email"
                          name="email"
                          value={email || ""}
                          placeholder="Email Id"
                          required
                        />
                      </div>
                      <div className="input-box mb--30">
                        <input
                          onChange={(e) =>
                            setLoginDetails({
                              ...loginDetails,
                              password: e.target.value,
                            })
                          }
                          type="password"
                          name="password"
                          value={password || ""}
                          style={{ fontSize: password?.length > 0 ? 33 : "" }}
                          placeholder="Password"
                          required
                        />
                      </div>

                      <p style={{ marginBottom: 0 }}>Don't have an account?</p>
                      <p
                        style={{ marginBottom: 0, cursor: "pointer" }}
                        className="lost-password"
                        onClick={(e) => {
                          e.preventDefault();
                          inputRef2.current.click();
                          navigate("/login-register");
                        }}
                      >
                        Register
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="rn-btn edu-btn btn-secondary"
                        style={{ lineHeight: 2, height: 40 }}
                        type="button"
                        data-dismiss="modal"
                        onClick={handleCancel}
                      >
                        <span>Cancel</span>
                      </button>

                      <button
                        className="rn-btn edu-btn"
                        style={{ lineHeight: 2, height: 40 }}
                        type="submit"
                      >
                        <span>Proceed</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {pageNo === 2 && (
              <div>
                <div className="modal-body">
                  <p>
                    Product :{" "}
                    <span style={{ fontWeight: "bold" }}>{data[nameKey]}</span>
                  </p>
                  <p>
                    Price :{" "}
                    <span style={{ fontWeight: "bold" }}>₹{data.Price}</span>
                  </p>
                  <p>
                    GST ({gst}%) :{" "}
                    <span style={{ fontWeight: "bold" }}>
                      ₹{data.Price * gst * 0.01}
                    </span>
                  </p>
                  {productType == 2 && (
                    <>
                      <br />
                      <p style={{ marginBottom: 3 }}>Confirm Address</p>
                      <div className="checkout-page-style">
                        <form
                          className="login-form"
                          action="#"
                          // onSubmit={makePayment}
                        >
                          <div className="row">
                            <div className="input-box mb--30 col-12">
                              <input
                                type="text"
                                name="address"
                                value={address || ""}
                                placeholder="Address"
                                required
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-6">
                              <div className="input-box mb--30">
                                <select
                                  name="state"
                                  // onBlur={handleStatesChange}
                                  value={myState || ""}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setMyState(e.target.value);
                                    setDistrict("");
                                  }}
                                >
                                  <option selected disabled value="">
                                    State
                                  </option>
                                  {statesList.map((item) => (
                                    <option>{item.StateTitle}</option>
                                  ))}
                                </select>
                              </div>

                              <div className="input-box mb--30">
                                <select
                                  placeholder="District"
                                  defaultValue={"District"}
                                  name="district"
                                  // onBlur={handleDistrictChange}
                                  onChange={(e) => setDistrict(e.target.value)}
                                  value={district || ""}
                                >
                                  <option selected disabled value="">
                                    District
                                  </option>
                                  {districtList.length > 0 &&
                                    districtList.map((item) => (
                                      <option>{item.DistrictTitle}</option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="input-box mb--30">
                                <input
                                  onChange={handleChange}
                                  type="text"
                                  className="form-control form-control-lg"
                                  name="city"
                                  placeholder="Village/Town/City"
                                  value={city || ""}
                                  required
                                />
                              </div>

                              <div className="input-box mb--30">
                                <input
                                  onChange={handleChange}
                                  type="number"
                                  className="form-control form-control-lg"
                                  name="pinCode"
                                  value={pinCode || ""}
                                  placeholder="Pincode"
                                  required
                                  min="100000"
                                  max="999999"
                                  onInvalid={(e) =>
                                    e.target.setCustomValidity(
                                      "Enter 6 digit number"
                                    )
                                  }
                                  onInput={(e) =>
                                    e.target.setCustomValidity("")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    className="rn-btn edu-btn btn-secondary"
                    style={{ lineHeight: 2, height: 40 }}
                    type="button"
                    data-dismiss="modal"
                    onClick={handleCancel}
                  >
                    <span>Cancel</span>
                  </button>

                  <button
                    className="rn-btn edu-btn"
                    style={{ lineHeight: 2, height: 40 }}
                    // type="submit"
                    onClick={makePayment}
                    disabled={processing}
                  >
                    <span>
                      {processing
                        ? "Processing ..."
                        : `Pay ₹${data.Price + (data.Price * gst) / 100}`}
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          sx={{ m: 1 }}
          severity={snackType}
          variant="filled"
        >
          {snackMsg}
        </Alert>
      </Snackbar>
    </>
  );
});

export default PurchaseDialog;
