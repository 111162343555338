import axios from "axios";

// export const BASE_URL = "http://15.206.52.141:3209/api";
export const BASE_URL = "https://api.tallyelearning.com/api";
// export const BASE_URL = "http://localhost:5023/api";

const instance = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json", Accept: "*/*" },
});

export default instance;
