import * as actionTypes from "../actions/actionTypes";

const initialState = {
  onlineCourses: [],
  books: [],
  exams: [],
  gst: [],
  invoice: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ONLINE_COURSES:
      return {
        ...state,
        onlineCourses: action.onlineCourses,
      };
    case actionTypes.GET_BOOKS:
      return {
        ...state,
        books: action.books,
      };
    case actionTypes.GET_EXAMS:
      return {
        ...state,
        exams: action.exams,
      };
    case actionTypes.GET_GST:
      return {
        ...state,
        gst: action.gst,
      };
    case actionTypes.GET_INVOICE:
      return {
        ...state,
        invoice: action.invoice,
      };
    default:
      return state;
  }
};

export default reducer;
