import React, { useState, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import CourseTypeOne from "./CourseTypeOne";
// import CourseData from "../../data/course/CourseData.json";
import CourseData from "../../data/course/CourseData.js";

const CourseTypeFilter = ({
  CourseData,
  title,
  idKey,
  nameKey,
  productType,
}) => {
  const [visibleItems, setVisibleItems] = useState([]);
  useEffect(() => {
    console.log("course data >>>", CourseData);
    setVisibleItems(CourseData);
  }, [CourseData]);

  return (
    <>
      <div className="row g-5 align-items-center mb--45 mb--30 mt_md--30 mt_sm--30">
        <div className="col-lg-6">
          <div className="section-title text-start">
            {/* <span className="pre-title">Who We Are</span> */}
            <h3 className="title">{title}</h3>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="button-group isotop-filter filters-button-group d-flex justify-content-start justify-content-lg-end">
            {/* {FilterControls.map((filter, i) => (
              <button
                onClick={handleChange}
                key={i}
                className={
                  filter.toLowerCase() === activeFilter ? "is-checked" : " "
                }
              >
                {filter}
              </button>
            ))} */}
          </div>
        </div>
      </div>

      <div className="row g-5">
        {visibleItems?.map((item, idx) => (
          <div className="col-12 col-sm-12 col-xl-4 col-md-6" key={idx}>
            <CourseTypeOne
              data={item}
              idKey={idKey}
              nameKey={nameKey}
              productType={productType}
              title={title}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default CourseTypeFilter;
