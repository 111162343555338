import React, { useEffect, useRef } from "react";
import SEO from "../../common/SEO";
import HeaderTwo from "../../common/header/HeaderTwo";
import BannerTwo from "../../components/banner/BannerTwo";
import HomeTwoService from "../../components/home-two/HomeTwoService";
import AboutFour from "../../components/about/AboutFour";
import HomeTwoCourses from "../../components/home-two/HomeTwoCourses";
import HomeTwoEvents from "../../components/home-two/HomeTwoEvents";
import HomeTwoTestimonial from "../../components/home-two/HomeTwoTestimonial";
import AboutOne from "../../components/about/AboutOne";
import CallToActionOne from "../../components/cta/CallToActionOne";
import HomeTwoBlog from "../../components/home-two/HomeTwoBlog";
import FooterOne from "../../common/footer/FooterOne";
import VideoSectionOne from "../../components/video-section/VideoSectionOne";
import TestimonialSectionFour from "../../components/testimonial-section/TestimonialSectionFour";

import { connect } from "react-redux";
import * as actions from "../../redux/actions";

const HomeTwo = (props) => {
  const courseRef = React.createRef();
  const bookRef = React.createRef();
  const gstRef = React.createRef();
  const testRef = useRef();

  const {
    onlineCourses,
    getOnlineCourses,
    getBooks,
    getExams,
    exams,
    books,
    getGst,
    gst,
    getStates,
  } = props;

  useEffect(() => {
    async function fetchData() {
      await Promise.all([
        getOnlineCourses(),
        getBooks(),
        getGst(),
        getStates(),
      ]);
    }
    fetchData();
  }, []);

  const handleScroll = (productType) => {
    console.log(productType);
    courseRef.current.scrollIntoView();
    if (productType == 3) {
      courseRef.current.scrollIntoView({
        behavior: "smooth",
      });
    } else if (productType == 2) {
      bookRef.current.scrollIntoView({
        behavior: "smooth",
      });
    } else if (productType == 4) {
      gstRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <SEO title="Home 2" />

      <HeaderTwo
        handleScroll={handleScroll}
        styles="header-transparent header-style-2"
        searchDisable
      />

      <BannerTwo />

      {/* <HomeTwoService /> */}

      <AboutFour />

      <div ref={courseRef}>
        <HomeTwoCourses
          onlineCourses={onlineCourses}
          title="Online Courses"
          nameKey="OnlineCourseName"
          idKey="OnlineCourseId"
          productType={3}
        />
      </div>
      <div ref={bookRef}>
        <HomeTwoCourses
          onlineCourses={books}
          title="Books"
          nameKey="BookName"
          idKey="BookMasterId"
          productType={2}
        />
      </div>
      {/* <div ref={gstRef}>
        <HomeTwoCourses
          onlineCourses={gst}
          title="GST Course"
          nameKey="GstProductTitle"
          idKey="GstPortalId"
          productType={4}
        />
      </div> */}

      <div
        ref={gstRef}
        className="edu-course-area eduvibe-home-two-course edu-section-gap bg-color-white"
      >
        <div className="container">
          <div className="row g-5 align-items-start mb--45 mt_md--30 mt_sm--30">
            <div className="col-lg-6">
              <div className="section-title text-start">
                {/* <span className="pre-title">Who We Are</span> */}
                <h3 className="title">GST Course</h3>
              </div>
            </div>
            <VideoSectionOne
              videoSrc={["https://www.youtube.com/watch?v=y6QP4vRA-74"]}
            />
            <TestimonialSectionFour
              courseData={gst[0]}
              title="GST Course"
              nameKey="GstProductTitle"
              idKey="GstPortalId"
              productType={4}
            />
          </div>
          <br />
          <div className="row ">
            <div className="col-lg-6">
              <h4>A Practical Approach to Teaching</h4>
              We have developed a unique and innovative concept where we focus
              on teaching practical aspects of accounting. Practical training
              makes sure that students will understand the fundamental concepts
              of accounting better as they interact with the topics instead of
              just learning the theory
              <br />
              <br />
              GST (Goods & Service Tax) is a major tax reform in the Country.
              Understanding how to practically use GST in the industry is now a
              primary requirement for each and every job seeker. This Course
              covers GST Return Filing practically and help you prepare for
              various GST requirements in the industry.
              <br />
              <br />
              This course is designed on a real world scenario where student
              will able to punch the data on dummy GST Portal through
              (Simulation Software)
              <br />
              <br />
              <h4>Course Benefit</h4>
              The 8 week program featuring real-world scenario-based learning,
              hands-on Practical with Courseware (E-Book) and Self learning
              Video Tutorials. <br />
              <br />
            </div>
            <div className="col-lg-6">
              <h4>Who Can join the Course ?</h4>
              Undergraduate, Graduate, Post graduate, students, Entrepreneurs,
              and student who want to up skill their knowledge of accounting and
              taxation can also join the Course. Specially those who have
              completed Tally Course.
              <br />
              <br />
              <h4>What Student Will Get?</h4>
              <ul>
                <li>A Separate Login ID to access the Course Online </li>
                <li> Video Tutorials and Assignments </li>
                <li>
                  Hands on Practical using Simulation Software ( Dummy GST
                  Portal ){" "}
                </li>
                <li> Online Quiz / Exam </li>
                <li> Digital Verifiable Internship Certificate . </li>
              </ul>
              <b style={{ color: "red" }}>Note: </b> No need of CA faculty to
              teach the Course, students can watch the Videos and Complete the
              Course.
            </div>
            <div className="col-lg-12">
              <h4>What you’ll learn:</h4>
              <div className="row">
                <div className="col-lg-6">
                  <ul>
                    <li>
                      GST Registration on Govt. Portal through Simulation
                      Software (Dummy GST Portal )
                    </li>
                    <li>Online GSTR-1 Filing</li>
                    <li>
                      Offline GSTR-1 Filing ( With the help of Tally Json File )
                    </li>
                    <li>Generating GSTR-1 Summary</li>
                    <li>Auto-Generated GSTR-2A</li>
                    <li>GSTR-3B Summary</li>
                    <li>Filing GSTR-3B using Offline Utility</li>
                    <li>Generating GSTR-3B Preview</li>
                    <li>Generating GSTR 3B Challan</li>
                    <li>Payment of Tax</li>
                    <li>Viewing auto-populated data for GSTR-2B</li>
                    <li>Quarterly Return Filing</li>
                    <li> GST – Nil Return Filing</li>
                    <li>GST Registration for Pvt. Ltd. Company.</li>
                    <li>Excess Purchase – No GST Payment.</li>
                    <li>
                      GST Amendments For:
                      <ul style={{ listStyleType: "circle" }}>
                        <li>For B2B Invoices</li>
                        <li>Edit Invoice No.</li>
                        <li>Edit Invoice Value</li>
                        <li>Edit GST No.</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul>
                    <li>
                      E-Way Bill Registration on Govt. Portal through Simulation
                      Software(Dummy E-way Bill Portal)
                    </li>
                    <li>Login Process</li>
                    <li>Single E-Way Bill uploading</li>
                    <li>
                      Bulk E-Way Bill Uploading with the help of Json File.
                    </li>
                    <li> Viewing / Downloading / Printing – E-Way Bill</li>
                    <li>Cancellation of E-Way Bill</li>
                    <li>
                      E-Invoice Registration Process on Govt. Portal through
                      SimulationSoftware (Dummy E-Invoice Portal)
                    </li>
                    <li>Generating Single E-Invoice</li>
                    <li>
                      Generating Bulk E-Invoice with the help of Json File.
                    </li>
                    <li>Viewing / Downloading / Printing E-Invoice.</li>
                    <li>Cancellation of E-Invoice.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <HomeTwoCourses onlineCourses={exams.filter(x=>x.ExamName == 'GST test')} title='GST Course'  nameKey='ExamName' idKey='ExamDetailsId' /> */}

      {/* <HomeTwoEvents /> */}

      {/* <HomeTwoTestimonial /> */}

      {/* <AboutOne wrapperClass="edu-section-gapBottom" /> */}

      {/* <CallToActionOne /> */}

      {/* <HomeTwoBlog /> */}
      <FooterOne />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    onlineCourses: state.courses.onlineCourses,
    books: state.courses.books,
    exams: state.courses.exams,
    gst: state.courses.gst,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOnlineCourses: () => dispatch(actions.GetOnlineCourses()),
    getBooks: () => dispatch(actions.GetBooks()),
    getExams: () => dispatch(actions.GetExams()),
    getGst: () => dispatch(actions.GetGst()),
    getStates: () => dispatch(actions.GetStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeTwo);
