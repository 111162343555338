import React from "react";
import { Link } from "react-router-dom";

const Nav = ({ handleScroll }) => {
  return (
    <ul className="mainmenu">
      <li>
        <Link to="/">Home</Link>
      </li>

      <li>
        <Link to="/about-us-3">About Us</Link>
      </li>

      {(window.location.hash == "#/" || window.location.hash == "") && (
        <li className="has-droupdown">
          <Link to="#">Our Products</Link>
          <ul className="submenu" style={{ minWidth: 280 }}>
            <li>
              {" "}
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScroll(3);
                }}
              >
                Online Courses
              </Link>{" "}
            </li>
            <li>
              {" "}
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScroll(2);
                }}
              >
                Books
              </Link>{" "}
            </li>
            <li>
              {" "}
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScroll(4);
                }}
              >
                GST Course
              </Link>{" "}
            </li>
          </ul>
        </li>
      )}

      <li className="has-droupdown">
        <Link to="#">Apply for reseller</Link>
        <ul className="submenu" style={{ minWidth: 280 }}>
          <li>
            <Link to="/apply-coordinator">Apply for District Coordinator</Link>
          </li>
          <li>
            <Link to="/apply-franchise">Apply for Franchise</Link>
          </li>
        </ul>
      </li>
      <li>
        {" "}
        <Link to="/contact-us">Contact Us</Link>{" "}
      </li>
    </ul>
  );
};
export default Nav;
