import * as actionTypes from "./actionTypes";
import axios from "../axios";

export const GetOnlineCourses = () => {
  return async (dispatch) => {
    await axios
      .get(`/Admin/OnlineCourse/GetAllCourses`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ONLINE_COURSES,
          onlineCourses: response.data,
        });
      })
      .catch((error) => {});
  };
};
export const GetBooks = () => {
  return async (dispatch) => {
    await axios
      .get(`/Admin/BookMaster/GetAllBooks`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_BOOKS,
          books: response.data,
        });
      })
      .catch((error) => {});
  };
};
export const GetExams = () => {
  return async (dispatch) => {
    await axios
      .get(`/CertificateExam/GetAllCertificateExam`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_EXAMS,
          exams: response.data,
        });
      })
      .catch((error) => {});
  };
};
export const GetGst = () => {
  return async (dispatch) => {
    await axios
      .get(`/Franchise/GstPortal/GetAllGstPortal`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_GST,
          gst: response.data.map((x) => ({
            ...x,
            SrcUrl: "/images/gst.jpg",
          })),
        });
      })
      .catch((error) => {});
  };
};

export const AssignWebsiteProduct = (data) => {
  return async (dispatch) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios
      .post(`/StudentCourse/AssignWebsiteProduct`, data, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const GetInvoiceByNumber = (invoiceNumber) => {
  return async (dispatch) => {
    await axios
      .get(`/Invoice/GetWebsiteInvoiceByNumber/${invoiceNumber}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_INVOICE,
          invoice: response.data,
        });
      })
      .catch((error) => {});
  };
};
