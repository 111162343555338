import React, { useEffect } from "react";
import SectionTitle from "../sectionTitle/SectionTitle";
import TestimonialOne from "../testimonial/TestimonialOne";
import Slider from "react-slick";
import { TestimonialOneParams } from "../../utils/script";
import { Link } from "react-router-dom";
import PurchaseDialog from "../course/PurchaseDialog";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";

let data = [
  {
    image: "/images/slider/slider-1.svg",
  },
  {
    image: "/images/slider/slider-2.webp",
  },
  {
    image: "/images/slider/slider-2.webp",
  },
];

const TestimonialSectionFour = ({
  title,
  nameKey,
  idKey,
  productType,
  courseData,
  ...props
}) => {
  const classes = `testimonial-activation testimonial-style-1 edu-slick-button slick-button-left ${
    props.className ? props.className : ""
  }`;
  data = props.item ? data?.slice(0, props.item) : data;

  const { getDistrictByState, statesList, districtList, assignProduct } = props;

  const purchaseDialogRef = React.useRef();

  const launchModal = () => {
    if (purchaseDialogRef.current) purchaseDialogRef.current.handleClick();
  };

  return (
    <div className="eduvibe-testimonial-one edu-testimonial-area edu-section-gap bg-image">
      <div className="container ">
        <div className="row g-5 mt--25">
          <div className="col-lg-6 col-12">
            <SectionTitle
              // slogan = "About Us"
              title="Certificate Course in Practical GST Return Filing Using Simulation  Software (Online)"
            />

            <div className="my-course-details">
              <div className="sub">
                Course Duration : <span>2 Months ( 60 Hours ) </span>
              </div>
              <div className="sub">
                Course Fees : <span>₹{courseData?.Price} </span>
              </div>
            </div>
            <div className="read-more-btn text-start">
              <Link
                className="edu-btn"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  launchModal();
                }}
              >
                Purchase Now<i className="icon-arrow-right-line-right"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <Slider className={classes} {...TestimonialOneParams}>
              {data.map((item, i) => (
                <div className="single-testimonial" key={i}>
                  <div className="inner">
                    <img src={item.image} alt="" />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {courseData && (
          <PurchaseDialog
            data={courseData}
            idKey={idKey}
            nameKey={nameKey}
            productType={productType}
            title={title}
            getDistrictByState={getDistrictByState}
            statesList={statesList}
            districtList={districtList}
            assignProduct={assignProduct}
            ref={purchaseDialogRef}
          />
        )}
      </div>
    </div>
  );
};

// export default TestimonialSectionFour;

const mapStateToProps = (state) => {
  return {
    districtList: state.districtAndState.districts,
    statesList: state.districtAndState.states,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    assignProduct: (data) => dispatch(actions.AssignWebsiteProduct(data)),
    getDistrictByState: (stateId) =>
      dispatch(actions.GetDistrictByState(stateId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestimonialSectionFour);
