import React from "react";
import { Link } from "react-router-dom";
import ScrollTopButton from "./ScrollTopButton";

const FooterOne = () => {
  return (
    <>
      <footer className="eduvibe-footer-one edu-footer footer-style-default">
        <div className="footer-top">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="edu-footer-widget">
                  {/* <div className="logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                     
                      <img
                        className="logo-light"
                        src="/images/logo/adminLogo5-white_.png"
                        alt="Footer Logo"
                      />
                    </Link>
                  </div> */}
                  <p className="description">
                    Our goal is to make you adapt at everything that Tally today
                    has to offer, apart from your simple accounting. And we just
                    happen to be pretty good at doing it!
                  </p>
                  <ul className="social-share">
                    <li>
                      <a href="https://www.facebook.com/TallyeLearning2018/">
                        <i className="icon-Fb"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/tallyelearning/">
                        <i className="icon-linkedin"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        <i className="icon-Pinterest"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://twitter.com/TallyeLearning">
                        <i className="icon-Twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget explore-widget">
                  <h5 className="widget-title">Explore</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      {/* <li>
                        <Link to="/about-us-3">
                          <i className="icon-Double-arrow"></i>About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/course-3">
                          <i className="icon-Double-arrow"></i>Courses
                        </Link>
                      </li> */}

                      <li>
                        <Link to="/apply-coordinator">
                          <i className="icon-Double-arrow"></i>Apply for
                          District Coordinator
                        </Link>
                      </li>
                      <li>
                        <Link to="/apply-franchise">
                          <i className="icon-Double-arrow"></i>Apply for
                          Franchise
                        </Link>
                      </li>
                      <li>
                        <Link to="/verify">
                          <i className="icon-Double-arrow"></i>Verify
                          Certificate
                        </Link>
                      </li>
                      <li>
                        <Link to="/verifygst">
                          <i className="icon-Double-arrow"></i>Verify GST
                          Certificate
                        </Link>
                      </li>
                      <li>
                        <Link to="/terms-condition">
                          <i className="icon-Double-arrow"></i>Terms and
                          Conditions
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">
                          <i className="icon-Double-arrow"></i>PrivacyPolicy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget quick-link-widget">
                  <h5 className="widget-title">User Login</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href =
                              process.env.REACT_APP_STUDENT_URL;
                          }}
                        >
                          <i className="icon-Double-arrow"></i>Student Login
                        </Link>
                      </li>
                      <li>
                        <Link to="/login-register">
                          <i className="icon-Double-arrow"></i>Student Register
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href = `${process.env.REACT_APP_ADMIN_URL}/session/signin/0`;
                          }}
                        >
                          <i className="icon-Double-arrow"></i>Admin Login
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href = `${process.env.REACT_APP_ADMIN_URL}/session/signin/1`;
                          }}
                        >
                          <i className="icon-Double-arrow"></i>Franchise Login
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href = `${process.env.REACT_APP_ADMIN_URL}/session/signin/2`;
                          }}
                        >
                          <i className="icon-Double-arrow"></i>District
                          Coordinator Login
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget">
                  <h5 className="widget-title">Contact Info</h5>
                  <div className="inner">
                    <div className="widget-information">
                      <ul className="information-list">
                        <li>
                          <i className="icon-map-pin-line"></i>Prompt
                          Enterprises 23, Municipal Colony, Nehru Nagar,W.B.
                          Road, Deopur, Dhule - 424 002 (Maharashtra)
                        </li>
                        <li>
                          <i className="icon-phone-fill"></i>
                          <a href="tel: +919665704080">+919665704080</a>
                        </li>
                        <li>
                          <i className="icon-phone-fill"></i>
                          <a href="tel: +919326272664">+919326272664</a>
                        </li>

                        {/* <li>
                          <i className="icon-mail-line-2"></i>
                          <a
                            target="_blank"
                            href="mailto:admin@tallyelearning.com"
                          >
                            admin@tallyelearning.com
                          </a>
                        </li>
                        <li>
                          <i className="icon-mail-line-2"></i>
                          <a
                            target="_blank"
                            href="mailto:support@tallyelearning.com"
                          >
                            support@tallyelearning.com
                          </a>
                        </li>*/}
                        <li>
                          <i className="icon-mail-line-2"></i>
                          <a
                            target="_blank"
                            href="mailto:tallybook.prompt@gmail.com"
                          >
                            tallybook.prompt@gmail.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-2">
                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area copyright-default">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <p>
                    Copyright 2022 <a href="#">Prompt Enterprises</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTopButton />
    </>
  );
};

export default FooterOne;
