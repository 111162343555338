import React, { useState } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import ContactUsForm from "../../components/contact/ContactUsForm";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { Alert, Snackbar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Result = () => {
  return (
    <p className="success-message">
      Thanks for your application. We will contact you soon.
    </p>
  );
};

const ApplyReseller = (props) => {
  const [snackMsg, setSnackMsg] = useState("");
  const [snackType, setSnackType] = useState("");
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ isActive: true, userStatus: 0 });

  const { applyCoordinator, applyFranchise, emailExist } = props;

  const {
    type,
    name,
    franchiseName,
    mobileNo,
    coordinatorName,
    address,
    emailId,
  } = state;

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    let values = { ...state };
    try {
      await emailExist(emailId);
    } catch (error) {
      console.log(error);
      if (error == "exists")
        setSnackMsg("It seems this email is already registered with us");
      else setSnackMsg("Something went wrong!");

      setSnackType("error");
      handleClick();
      return;
    }
    values.coordinatorName = name;
    values.CpMobileNo = mobileNo;
    try {
      await applyCoordinator(values);
      setState({});
      setSnackMsg("Application Submitted successfully");
      setSnackType("success");
      handleClick();
      setResult(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  setTimeout(() => {
    setResult(false);
  }, 60000);

  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  return (
    <>
      <SEO title="Apply for District Coordinator" />
      <Layout>
        <BreadcrumbOne
          title="Apply for District Coordinator"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Apply for District Coordinator"
        />
        <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">Get in Business with Us</span>
                  <h3 className="title">Apply for District Coordinator</h3>
                </div>
              </div>
            </div>
            <div
              className="row g-5 mt--20"
              style={{ width: "80%", margin: "auto" }}
            >
              <form
                className="rnt-contact-form rwt-dynamic-form row"
                action=""
                onSubmit={sendEmail}
              >
                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <select name="type" onChange={handleChange}>
                      <option selected disabled>
                        Reseller Type
                      </option>
                      <option>Franchise</option>
                      <option>District Coordinator</option>
                    </select>
                  </div>
                </div> */}
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="text"
                      className="form-control form-control-lg"
                      name="name"
                      placeholder="Name*"
                      value={name || ""}
                      required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="email"
                      className="form-control form-control-lg"
                      name="emailId"
                      placeholder="Email*"
                      value={emailId || ""}
                      required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="number"
                      className="form-control form-control-lg"
                      name="mobileNo"
                      value={mobileNo || ""}
                      placeholder="Phone"
                      required
                      min="1000000000"
                      max="9999999999"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Enter 10 digit number")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      type="text"
                      className="form-control form-control-lg"
                      name="address"
                      value={address || ""}
                      placeholder="Address"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  {!loading ? (
                    <button className="rn-btn edu-btn w-100" type="submit">
                      <span>Apply</span>
                      <i className="icon-arrow-right-line-right"></i>
                    </button>
                  ) : (
                    <button className="rn-btn edu-btn w-100" type="submit">
                      <CircularProgress />
                    </button>
                  )}
                </div>
                {result ? (
                  <div className="form-group">
                    <Result />
                  </div>
                ) : null}
              </form>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-2">
                <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-3">
                <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
        </div>

        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            sx={{ m: 1 }}
            severity={snackType}
            variant="filled"
          >
            {snackMsg}
          </Alert>
        </Snackbar>

        {/* <div className="edu-contact-map-area edu-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="google-map alignwide">
                  <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1114.617608948499!2d73.74915253208648!3d20.003821623750902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeb8736b45c4d%3A0xa0156cf23367fba7!2sTechbona%20Infomatics%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1663999008594!5m2!1sen!2sin"
                    // width="600"
                    height="500"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    tabIndex="0"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  // console.log(state.franchise.franchiseDetails);
  // console.log(state)
  return {
    //   franchiseDetails: state.franchise.franchiseDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    applyFranchise: (data) => dispatch(actions.ApplyFranchise(data)),
    applyCoordinator: (data) => dispatch(actions.ApplyCoordinator(data)),
    emailExist: (email) => dispatch(actions.CheckCoordinatorEmail(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyReseller);
