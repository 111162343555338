import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../redux/actions";
import { format } from "date-fns";
import { Button, Icon, styled, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";

import QRCode from "qrcode";

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const ViewGstCertificate = (props) => {
  const navigate = useNavigate();
  const componentRef = useRef();
  const canvas2Ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const { getCertificate, certificate, certNo } = props;

  const { certno } = useParams();
  const [profileEmpty, setProfileEmpty] = useState(false);

  const drawImage2 = async (canvas, image, exam, profile, qrcode, tick) => {
    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    try {
      ctx.drawImage(profile, 498, 426, 188, 260);
    } catch (error) {
      console.log(error);
    }

    ctx.fillStyle = "#2e";
    ctx.font = "44px FreeSans";
    ctx.textAlign = "center";
    ctx.fillText(`${exam?.StudentName}`, 584, 800);

    ctx.textAlign = "left";
    ctx.textAlign = "left";
    ctx.fillStyle = "black";
    ctx.font = "20px FreeSans";
    ctx.fillText(
      `${format(
        Date.parse(
          exam?.CourseAssignDate != null
            ? exam?.CourseAssignDate
            : `${new Date()}`
        ),
        "dd/MM/yyyy"
      )}`,
      481,
      1075
    );
    ctx.fillText(
      `${format(
        Date.parse(
          exam?.CertificateIssueDate != null
            ? exam?.CertificateIssueDate
            : `${new Date()}`
        ),
        "dd/MM/yyyy"
      )}`,
      800,
      1075
    );

    ctx.font = "26px FreeSans";
    ctx.fillText(
      `${exam.FranchiseName?.toUpperCase()}, ${exam.City?.toUpperCase()}`,
      169,
      1145
    );
    ctx.fillText(`${exam?.SerialNumber}`, 281, 1203);

    ctx.fillText(
      `${format(
        Date.parse(
          exam?.CertificateIssueDate != null
            ? exam?.CertificateIssueDate
            : `${new Date()}`
        ),
        "dd MMMM yyyy"
      )}`,
      270,
      1260
    );

    ctx.font = "20px FreeSans";
    ctx.fillText("Document certified by Ajay M", 711, 1268);
    ctx.font = "18px FreeSans";
    ctx.fillText("Digitally signed by Ajay M", 711, 1292);
    ctx.fillText(`Dated:${exam?.CertificateIssueDate}`, 711, 1315);
    ctx.font = "15px FreeSans";
    ctx.fillText(
      "Digitally signed document, hence does not need signature",
      711,
      1338
    );

    try {
      ctx.drawImage(tick, 850, 1250, 100, 100);
    } catch (error) {
      console.log(error);
    }

    try {
      ctx.drawImage(qrcode, 133, 1275, 135, 135);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (certNo) {
        await getCertificate(certNo);
      } else {
        await getCertificate(certno);
      }
    }
    fetchData();
  }, [certno, certNo]);

  useEffect(() => {
    if (certificate != null) {
      RenderCertificate(certificate);
    }
  }, [certificate]);

  const RenderCertificate = async (certificate) => {
    setLoading(true);
    const image2 = new Image();
    image2.src = "/images/Certificate/gst_certificate2.jpg";
    const canvas2 = canvas2Ref.current;
    canvas2.width = 1188;
    canvas2.height = 1680;
    await image2.decode();

    let profile = new Image();
    profile.src = certificate.StudentProfileUrl;
    profile.crossOrigin = "anonymous";
    await profile.decode();

    let qrcode = new Image();
    await QRCode.toDataURL(certificate.QrCodeUrls).then((data) => {
      qrcode.src = data;
    });
    qrcode.crossOrigin = "anonymous";
    await qrcode.decode();

    let tick = new Image();
    tick.src = "/images/Tick-Mark.png";
    tick.crossOrigin = "anonymous";
    await tick.decode();
    setLoading(false);
    drawImage2(canvas2, image2, certificate, profile, qrcode, tick);
  };

  const getCursorPos = (event) => {
    const canvas2 = canvas2Ref.current;
    const rect = canvas2.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
  };

  const download = () => {
    const imageLink = document.createElement("a");
    const canvas = canvas2Ref.current;
    // imageLink.download = `Certificate-${exam.FirstName}_${exam.LastName}-${exam.ExamName}.png`;
    imageLink.download = `Certificate-${certificate.StudentName}.png`;
    imageLink.href = canvas.toDataURL("image/png", 1);
    // document.write(`<img src="${imageLink}"/>`);
    imageLink.click();
  };
  return (
    <>
      <div style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: 10,
            marginBottom: 20,
          }}
        >
          <ArrowBackIcon
            style={{ marginRight: 50, cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />

          <Tooltip title="hit me if certificate doesn't load properly">
            <RefreshIcon
              style={{ marginRight: 50, cursor: "pointer" }}
              onClick={() => window.location.reload(true)}
            />
          </Tooltip>

          <StyledButton
            variant="contained"
            color="inherit"
            style={{ margin: 0, marginRight: 20 }}
            onClick={download}
            disabled={profileEmpty}
          >
            <DownloadIcon />
            Download Certificate
          </StyledButton>
        </div>
        {/* <img src={qr} alt="" /> */}
        {loading && <h3>Loading...</h3>}
        <div ref={componentRef}>
          {/* {profileEmpty ? (
              <p style={{ color: "red", fontWeight: "bold" }}>
                Please update student profile photo
              </p>
            ) : (
              ""
            )} */}
          <canvas
            style={{ border: "1px solid" }}
            onMouseDown={getCursorPos}
            ref={canvas2Ref}
            id="canvas"
          ></canvas>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    certificate: state.exam.studentCertificate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCertificate: (certno) => dispatch(actions.GetStudentCertificate(certno)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewGstCertificate);
